body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bogart-light';
  src: local('BogartLight'), url('src/assets/Fonts/Bogart/Bogart-Light-trial.ttf') format("truetype");
}

@font-face {
  font-family: 'Bogart-regular';
  src: local('BogartRegular'), url('src/assets/Fonts/Bogart/Bogart-Regular-trial.ttf') format("truetype");
}

@font-face {
  font-family: 'Bogart-bold';
  src: local('BogartBold'), url('src/assets/Fonts/Bogart/Bogart-Bold-trial.ttf') format("truetype");
}